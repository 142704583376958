<template>
  <Layout class="organization-structure os">
    <h4 class="os__title">Organization structure</h4>
    <div ref="os-content" class="os__content mt-3">
      <div @click="showInfo(2)" class="os__main os-block">
        Chief Executive Officer
      </div>
      <div class="os__departments">
        <div class="os__department">
          <div class="text-center">
            <h5 class="os__department-name">General</h5>
          </div>
          <div class="os__level-1 os__level">
            <div @click="showInfo(2)" class="os-block">Chief Officer</div>
            <div class="os__level-2 os__level">
              <div class="os__level-cover" style="background-color: red;"></div>
              <div class="os__level-line" style="background-color: red;"></div>
              <div @click="showInfo(2)" class="os-block">Chief Officer</div>
              <div class="os__level-3 os__level">
                <div class="os__level-cover" style="background-color: blue;"></div>
                <div class="os__level-line" style="background-color: blue;"></div>
                <div @click="showInfo(2)" class="os-block">Chief Officer</div>
              </div>
            </div>
            <div class="os__level-2 os__level">
              <div class="os__level-cover" style="background-color: red;"></div>
              <div class="os__level-line" style="background-color: red;"></div>
              <div @click="showInfo(2)" class="os-block">Chief Officer</div>
              <div class="os__level-3 os__level">
                <div class="os__level-line" style="background-color: blue;"></div>
                <div class="os__level-cover" style="background-color: blue;"></div>
                <div @click="showInfo(2)" class="os-block">Chief Officer</div>
              </div>
              <div class="os__level-3 os__level">
                <div class="os__level-line" style="background-color: blue;"></div>
                <div class="os__level-cover" style="background-color: blue;"></div>
                <div @click="showInfo(2)" class="os-block">Chief Officer</div>
              </div>
            </div>
          </div>
        </div>
        <div class="os__department">
          <div class="text-center">
            <h5 class="os__department-name">Apps</h5>
            <div class="os__level-1 os__level">
              <div @click="showInfo(2)" class="os-block">Chief Officer</div>
              <div class="os__level-2 os__level">
                <div class="os__level-cover" style="background-color: red;"></div>
                <div class="os__level-line" style="background-color: red;"></div>
                <div @click="showInfo(2)" class="os-block">Chief Officer</div>
                <div class="os__level-3 os__level">
                  <div class="os__level-cover" style="background-color: red;"></div>
                  <div class="os__level-line" style="background-color: red;"></div>
                  <div @click="showInfo(2)" class="os-block">Chief Officer</div>
                </div>
              </div>
              <div class="os__level-2 os__level">
                <div class="os__level-cover" style="background-color: red;"></div>
                <div class="os__level-line" style="background-color: red;"></div>
                <div @click="showInfo(2)" class="os-block">Chief Officer</div>
                <div class="os__level-3 os__level">
                  <div class="os__level-cover" style="background-color: red;"></div>
                  <div class="os__level-line" style="background-color: red;"></div>
                  <div @click="showInfo(2)" class="os-block">Chief Officer</div>
                </div>
                <div class="os__level-3 os__level">
                  <div class="os__level-cover" style="background-color: red;"></div>
                  <div class="os__level-line" style="background-color: red;"></div>
                  <div @click="showInfo(2)" class="os-block">Chief Officer</div>
                </div>
              </div>
            </div>
            <div class="os__level-1 os__level">
              <div @click="showInfo(2)" class="os-block">Chief Officer</div>
              <div class="os__level-2 os__level">
                <div class="os__level-cover" style="background-color: red;"></div>
                <div class="os__level-line" style="background-color: red;"></div>
                <div @click="showInfo(2)" class="os-block">Chief Officer</div>
                <div class="os__level-3 os__level">
                  <div class="os__level-cover" style="background-color: red;"></div>
                  <div class="os__level-line" style="background-color: red;"></div>
                  <div @click="showInfo(2)" class="os-block">Chief Officer</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="os__department">
          <div class="text-center">
            <h5 class="os__department-name">Cartoon</h5>
            <div class="os__level-1 os__level">
              <div @click="showInfo(2)" class="os-block">Chief Officer</div>
              <div class="os__level-2 os__level">
                <div @click="showInfo(2)" class="os-block">Chief Officer</div>
                <div class="os__level-3 os__level">
                  <div @click="showInfo(2)" class="os-block">Chief Officer</div>
                </div>
              </div>
              <div class="os__level-2 os__level">
                <div @click="showInfo(2)" class="os-block">Chief Officer</div>
                <div class="os__level-3 os__level">
                  <div @click="showInfo(2)" class="os-block">Chief Officer</div>
                </div>
                <div class="os__level-3 os__level">
                  <div @click="showInfo(2)" class="os-block">Chief Officer</div>
                </div>
              </div>
            </div>
            <div class="os__level-1 os__level">
              <div class="os-block">Chief Officer</div>
              <div class="os__level-2 os__level">
                <div class="os-block">Chief Officer</div>
                <div class="os__level-3 os__level">
                  <div class="os-block">Chief Officer</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="os__department">
          <div class="text-center">
            <h5 class="os__department-name">Technical</h5>
            <div class="os__level-1 os__level">
              <div @click="showInfo(2)" class="os-block">Chief Officer</div>
              <div class="os__level-2 os__level">
                <div @click="showInfo(2)" class="os-block">Chief Officer</div>
                <div class="os__level-3 os__level">
                  <div @click="showInfo(2)" class="os-block">Chief Officer</div>
                </div>
              </div>
              <div class="os__level-2 os__level">
                <div @click="showInfo(2)" class="os-block">Chief Officer</div>
                <div class="os__level-3 os__level">
                  <div @click="showInfo(2)" class="os-block">Chief Officer</div>
                </div>
                <div class="os__level-3 os__level">
                  <div @click="showInfo(2)" class="os-block">Chief Officer</div>
                </div>
              </div>
            </div>
            <div class="os__level-1 os__level">
              <div @click="showInfo(2)" class="os-block">Chief Officer</div>
              <div class="os__level-2 os__level">
                <div @click="showInfo(2)" class="os-block">Chief Officer</div>
                <div class="os__level-3 os__level">
                  <div @click="showInfo(2)" class="os-block">Chief Officer</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="os__department">
          <div class="text-center">
            <h5 class="os__department-name">Technical</h5>
            <div class="os__level-1 os__level">
              <div @click="showInfo(2)" class="os-block">Chief Officer</div>
              <div class="os__level-2 os__level">
                <div @click="showInfo(2)" class="os-block">Chief Officer</div>
                <div class="os__level-3 os__level">
                  <div @click="showInfo(2)" class="os-block">Chief Officer</div>
                </div>
              </div>
              <div class="os__level-2 os__level">
                <div @click="showInfo(2)" class="os-block">Chief Officer</div>
                <div class="os__level-3 os__level">
                  <div @click="showInfo(2)" class="os-block">Chief Officer</div>
                </div>
                <div class="os__level-3 os__level">
                  <div @click="showInfo(2)" class="os-block">Chief Officer</div>
                </div>
              </div>
            </div>
            <div class="os__level-1 os__level">
              <div @click="showInfo(2)" class="os-block">Chief Officer</div>
              <div class="os__level-2 os__level">
                <div @click="showInfo(2)" class="os-block">Chief Officer</div>
                <div class="os__level-3 os__level">
                  <div @click="showInfo(2)" class="os-block">Chief Officer</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="os__department">
          <div class="text-center">
            <h5 class="os__department-name">Retail</h5>
            <div class="os__level-1 os__level">
              <div @click="showInfo(2)" class="os-block">Chief Officer</div>
              <div class="os__level-2 os__level">
                <div @click="showInfo(2)" class="os-block">Chief Officer</div>
                <div class="os__level-3 os__level">
                  <div @click="showInfo(2)" class="os-block">Chief Officer</div>
                </div>
              </div>
              <div class="os__level-2 os__level">
                <div @click="showInfo(2)" class="os-block">Chief Officer</div>
                <div class="os__level-3 os__level">
                  <div @click="showInfo(2)" class="os-block">Chief Officer</div>
                </div>
                <div class="os__level-3 os__level">
                  <div @click="showInfo(2)" class="os-block">Chief Officer</div>
                </div>
              </div>
            </div>
            <div class="os__level-1 os__level">
              <div @click="showInfo(2)" class="os-block">Chief Officer</div>
              <div class="os__level-2 os__level">
                <div @click="showInfo(2)" class="os-block">Chief Officer</div>
                <div class="os__level-3 os__level">
                  <div @click="showInfo(2)" class="os-block">Chief Officer</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="os__department">
          <div class="text-center">
            <h5 class="os__department-name">Marketing</h5>
            <div class="os__level-1 os__level">
              <div @click="showInfo(2)" class="os-block">Chief Marketing Officer</div>
            </div>
            <div class="os__level-1 os__level">
              <div @click="showInfo(2)" class="os-block">Chief Design Officer</div>
              <div class="os__level-2 os__level">
                <div class="os__level-cover" style="background-color: red;"></div>
                <div class="os__level-line" style="background-color: red;"></div>
                <div @click="showInfo(2)" class="os-block">Art Director Video Content</div>
                <div class="os__level-3 os__level">
                  <div class="os__level-cover" style="background-color: red;"></div>
                  <div class="os__level-line" style="background-color: red;"></div>
                  <div @click="showInfo(2)" class="os-block">Storyboarder</div>
                </div>
              </div>
              <div class="os__level-2 os__level">
                <div class="os__level-cover" style="background-color: red;"></div>
                <div class="os__level-line" style="background-color: red;"></div>
                <div @click="showInfo(2)" class="os-block">Art Director Graphic Content</div>
                <div class="os__level-3 os__level">
                  <div class="os__level-cover" style="background-color: red;"></div>
                  <div class="os__level-line" style="background-color: red;"></div>
                  <div @click="showInfo(2)" class="os-block">Graphic Designer</div>
                </div>
              </div>
              <div class="os__level-2 os__level">
                <div class="os__level-cover" style="background-color: red;"></div>
                <div class="os__level-line" style="background-color: red;"></div>
                <div @click="showInfo(2)" class="os-block">Art Director AB Testing</div>
                <div class="os__level-3 os__level">
                  <div class="os__level-cover" style="background-color: red;"></div>
                  <div class="os__level-line" style="background-color: red;"></div>
                  <div @click="showInfo(2)" class="os-block">Content Artist</div>
                </div>
                <div class="os__level-3 os__level">
                  <div class="os__level-cover" style="background-color: red;"></div>
                  <div class="os__level-line" style="background-color: red;"></div>
                  <div @click="showInfo(2)" class="os-block">Graphic Designer</div>
                </div>
              </div>
            </div>
            <div class="os__level-1 os__level-2_a os__level">
              <div @click="showInfo(2)" class="os-block">Marketing Manager Cartoons</div>
            </div>
            <div class="os__level-1 os__level-2_a os__level">
              <div @click="showInfo(2)" class="os-block">Marketing Manager Retail</div>
              <div class="os__level-3 os__level">
                <div class="os__level-cover" style="background-color: red;"></div>
                <div class="os__level-line" style="background-color: red;"></div>
                <div @click="showInfo(2)" class="os-block">Graphic Designer</div>
              </div>
            </div>
            <div class="os__level-1 os__level-2_a os__level">
              <div @click="showInfo(2)" class="os-block">PR Manager</div>
            </div>
            <div class="os__level-1 os__level-2_a os__level">
              <div @click="showInfo(2)" class="os-block">Sales manager</div>
            </div>
            <div class="os__level-1 os__level-2_a os__level">
              <div @click="showInfo(2)" class="os-block">Data Engineer</div>
            </div>
            <div class="os__level-1 os__level-2_a os__level">
              <div @click="showInfo(2)" class="os-block">Aso Specialist</div>
              <div class="os__level-3 os__level">
                <div class="os__level-cover" style="background-color: red;"></div>
                <div class="os__level-line" style="background-color: red;"></div>
                <div @click="showInfo(2)" class="os-block">Graphic Designer</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="staff" class="modal-block">
      <div class="staff">
        <div class="staff__block d-flex">
          <div :style="{backgroundImage: `url(https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1180&q=80)`}" class="staff__avatar"></div>
          <div class="staff__info">
            <p class="staff__badge badge badge-primary">Apps</p>
            <p class="staff__name font-weight-bold">Raul Sayfulin</p>
            <p class="staff__name mb-3">Application Developer</p>
            <p class="staff__link"><a href="mailto:asdasd@mail.com">asdasd@mail.com</a></p>
            <p class="staff__link"><a href="tel:+998909998004">+998909998004</a></p>
          </div>
        </div>
        <a @click="closeInfo" class="staff__close">&times;</a>
        <swiper
          ref="swiperComponentRef"
          class="swiper component mt-4"
          :options="swiperOptions"
        >
          <swiper-slide class="carousel-slide" v-for="(item, index) in 10" :key="item">
            <div
              @click="showStaff(index)"
              class="carousel-slide__image"
              :class="{'carousel-slide__image_active': chooseStaffIndex === index}"
              :style="{backgroundImage: `url(https://picsum.photos/1024/480/?image=55)`}"
            ></div>
          </swiper-slide>
        </swiper>
        <a @click="prevSlide" class="swiper-navigation swiper-navigation_left"><i class="fas fa-angle-left"></i></a>
        <a @click="nextSlide" class="swiper-navigation swiper-navigation_right"><i class="fas fa-angle-right"></i></a>
      </div>
      <div @click="closeInfo" class="close"></div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
export default {
  components: {
    Layout,
  },
  data() {
    return {
      staff: null,
      content: null,
      scrollSize: 0,
      isHoverOnInfo: false,
      isHoverOnBoard: false,
      isInfoMouseOut: false,
      modalId: null,
      slide: 0,
      sliding: null,
      swiperOptions: {
        slidesPerView: 'auto',
        spaceBetween: 24,
        simulateTouch: false
      },
      chooseStaffIndex: 0
    }
  },
  methods: {
    showInfo(item) {
      this.staff = item
      this.modalId = `staff-${item}`
      setTimeout(() => {
        this.$bvModal.show(this.modalId)
      }, 100)
      // let block = e.toElement || e.relatedTarget
      // let params = block.getBoundingClientRect()
      // this.isHoverOnBoard = true
      // if (this.staff && this.staff.id === item) {
      //   return
      // }
      // this.staff = ({
      //   id: item,
      //   top: params.top + params.height + 100 < window.innerHeight ? `${params.top + this.scrollSize + params.height - 10}px` : `${e.screenY - this.content.top + this.scrollSize - 150}px`,
      //   right: 'auto',
      //   bottom: 'auto',
      //   left: params.left + 200 < window.innerWidth ? `${params.left - this.content.left + params.width}px` : `${params.left - this.content.left - 150}px`,
      // })
    },
    hideInfo() {
      this.isHoverOnBoard = false
      setTimeout(() => {
        if (!this.isHoverOnInfo) {
          this.staff = null
        }
      }, 100)
    },
    calculateScrollSize() {
      this.scrollSize = window.scrollY
    },
    showInfoHoverHandler() {
      this.isHoverOnInfo = true
    },
    hideInfoHoverHandler(event) {
      let e = event.toElement || event.relatedTarget
      if ( e && (e.parentNode === this.$refs.info || e === this.$refs.info)) {
        return
      }
      setTimeout(() => {
        if (!this.isHoverOnBoard) {
          this.staff = null
          this.isHoverOnInfo = false
        } else {
          this.isHoverOnInfo = false
        }
      }, 100)
    },
    closeInfo() {
      this.staff = null
    },
    onSlideStart() {
      this.sliding = true
    },
    onSlideEnd() {
      this.sliding = false
    },
    prevSlide() {
      const swiper = this.$refs.swiperComponentRef.$swiper
      if (!swiper) {
        return
      }
      this.chooseStaffIndex = this.chooseStaffIndex - 1 < 0 ? 9 : this.chooseStaffIndex - 1
      swiper.slideTo(this.chooseStaffIndex, 500)
    },
    nextSlide() {
      const swiper = this.$refs.swiperComponentRef.$swiper
      if (!swiper) {
        return
      }
      this.chooseStaffIndex = Math.abs((this.chooseStaffIndex + 1) % 10)
      swiper.slideTo(this.chooseStaffIndex, 500)
    },
    showStaff(index) {
      this.chooseStaffIndex = index
      const swiper = this.$refs.swiperComponentRef.$swiper
      if (!swiper) {
        return
      }
      swiper.slideTo(this.chooseStaffIndex, 500)
    }
  },
  mounted() {
    // this.content = this.$refs['os-content']?.getBoundingClientRect()
    // document.addEventListener('scroll', this.calculateScrollSize)
  },
  beforeDestroy() {
    // document.removeEventListener('scroll', this.calculateScrollSize)
  }
}
</script>

<style scoped lang="scss">
.os__content {
  width: 100%;
  overflow: auto;
  padding-left: 20px;
}
.os-block {
  line-height: 13px;
  padding: 2px 10px;
  height: 53px;
  border-radius: 4px;
  font-weight: 600;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
  width: 100%;
  margin-top: 20px;
  position: relative;
  white-space: normal;
  cursor: pointer;
}
.os__main {
  width: 120px;
  margin: auto;
  background-color: #fa8c8c;
  &:after {
    border: 3px solid transparent;
    border-right: none;
    border-top: none;
    bottom: 50%;
    border-bottom-left-radius: 12px;
    position: absolute;
    content: '';
    height: 40px;
    top: 100%;
    width: 3px;
    background-color: inherit;
    left: 50%;
    transform: translateX(-50%);
  }
}
.os__departments {
  width: 100%;
  padding-top: 40px;
  position: relative;
  display: flex;
  justify-content: space-between;
  white-space: nowrap;
  &:before {
    width: 81%;
    height: 1px;
    border-top: 3px solid #fa8c8c;
    position: absolute;
    content: '';
    left: 50%;
    transform: translateX(-50%);
    top: 0;
    margin-top: 40px;
  }
}
.os__department {
  width: 150px;
  min-width: 150px;
}
.os__department:not(:last-child) {
  margin-right: 40px;
}
.os__department-name {
  padding-top: 20px;
  position: relative;
  &:before, &:after {
    position: absolute;
    content: '';
    height: 20px;
    width: 3px;
    border-left: 3px solid #fa8c8c;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  &:after {
    top: 100%;
  }
}
.os__department:first-child .os__department-name:before, .os__department:last-child .os__department-name:before, .os__department:nth-last-child(2) .os__department-name:before {
  width: calc(100% + 40px);
  left: calc(100% + 20px);
  border-top: 3px solid #fa8c8c;
}
.os__department:first-child .os__department-name:before {
  border-top-left-radius: 12px;
}
.os__department:last-child .os__department-name:before, .os__department:nth-last-child(2) .os__department-name:before {
  border-left: none;
  border-right: 3px solid #fa8c8c;
  left: auto;
  border-top-right-radius: 12px;
  right: 50%;
  transform: translateX(0);
}
.os__department:nth-last-child(2) .os__department-name:before {
  border-top-right-radius: 0;
}
.os__level {
  position: relative;
  z-index: 2;
  //&:last-child:after {
  //  content: none;
  //}
  //&:after {
  //  position: absolute;
  //  content: '';
  //  width: 3px;
  //  height: 100%;
  //  //top: 20px;
  //  top: 0;
  //  left: -20px;
  //}
  .os__level-cover {
    position: absolute;
    //width: 5px;
    height: 100%;
    z-index: 4;
    &:after {
      position: absolute;
      content: '';
      width: 3px;
      height: 100%;
      //top: 20px;
      top: 0;
      left: -20px;
      background: inherit;
    }
  }
  &:last-child > .os__level-cover:after {
    content: none;
  }
}
.os__level-line {
  position: absolute;
  width: 0;
  height: 100%;
  &:before, &:after {
    background-color: inherit;
    content: '';
    width: 20px;
    left: -20px;
    top: -20px;
    border: 3px solid transparent;
    border-right: none;
    border-top: none;
    bottom: 50%;
    height: 53px;
    position: absolute;
    border-bottom-left-radius: 12px;
  }
  &:after {
    background-color: #FFFFFF;
    width: 17px;
    left: -17px;
    border: none;
    height: 50px;
    border-bottom-left-radius: 8px;
  }
}
.os__level-1 {
  position: relative;
  &:last-child:after {
    content: none;
  }
  &:after {
    position: absolute;
    content: '';
    width: 3px;
    height: 100%;
    top: 0;
    left: -20px;
    background: #fa8c8c;
  }
  &>.os-block {
    background-color: #fab37a;
    &:after {
      content: '';
      width: 20px;
      left: -20px;
      border: 3px solid transparent;
      border-right: none;
      border-top: none;
      bottom: 50%;
      height: calc(100%);
      position: absolute;
      border-bottom-left-radius: 12px;
      border-color: #fa8c8c;
    }
  }
  &:last-child {
    &:after {
      content: none;
    }
    &> .os-block {
      &:after {
        content: none;
      }
    }
  }
  &:not(:nth-child(2)):last-child {
    &> .os-block {
      &:after {
        content: '';
      }
    }
  }
  &:not(:last-child):nth-child(2) {
    &:after {
      top: 40px;
      height: calc(100% - 40px);
    }
    &> .os-block {
      &:after {
        border-top: 3px solid #fa8c8c;
        border-bottom: none;
        border-radius: 0;
        top: 50%;
        border-top-left-radius: 12px;
      }
    }
  }
}
.os__level-2 {
  width: calc(100% - 20px);
  margin: 0 0 0 auto;
  //&:after {
  //  background: #fab37a;
  //}
  &>.os-block {
    background-color: #75b1e0;
    //&:after {
    //  border-color: #fab37a;
    //}
  }
}
.os__level-2_a {
  &>.os-block {
    background-color: #88d16b;
  }
}
.os__level-3 {
  width: calc(100% - 20px);
  margin: 0 0 0 auto;
  //&:after {
  //  background: #75b1e0;
  //}
  &>.os-block {
    background-color: #8499e6;
    //&:after {
    //  border-color: #75b1e0;
    //}
  }
}
.os__level-3_a {
  &>.os-block {
    background-color: #a2b6bd;
  }
}
.os__level-2_a .os__level-3 {
  //&:after {
  //  background: #88d16b;
  //}
  &>.os-block {
    //&:after {
    //  border-color: #88d16b;
    //}
  }
}
.staff {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #FFFFFF;
  width: 500px;
  border-radius: 8px;
  padding: 2rem 2rem;
  z-index: 2;
}
.staff__avatar {
  width: 200px;
  height: 200px;
  border-radius: 8px;
  background-size: cover;
  background-position: center center;
  margin-right: 1rem;
}
.staff__name, .staff__link {
  margin: 0;
}
.staff__badge {
  text-transform: uppercase;
  padding: 4px 10px;
  border-radius: 20px;
}
.staff__close {
  position: absolute;
  right: 10px;
  top: 4px;
  font-size: 22px;
  color: #BFC9F9;
  cursor: pointer;
}
.carousel-slide, .swiper-slide {
  width: 55px;
  height: 55px;
  cursor: pointer;
}
.carousel-slide__image {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
  border-radius: 4px;
}
.swiper-navigation {
  position: absolute;
  bottom: 45px;
  left: 10px;
  font-size: 22px;
  color: #BFC9F9;
  cursor: pointer;
}
.swiper-navigation_right {
  left: auto;
  right: 10px;
}
.carousel-slide__image_active {
  border: 2px solid #BFC9F9;
}
.modal-block {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 2;
}
.close {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  cursor: pointer;
}
</style>